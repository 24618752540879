import React from 'react'
import {
  About,
  Breadcrumbs,
  Bullets,
  Director,
  Figures,
  Hero,
  Page,
} from '@components'

const meta = {
  title: 'about.meta.title',
  description: 'about.meta.desription',
}

const AboutPage = ({ pageContext }) => {
  
  return (
    <Page
      isHomepage
      locale={pageContext.locale}
      meta={meta}
    >
      <Breadcrumbs
        links={[
          { link: '/', name: 'header.link.home'},
          { link: '/o-nama', name: 'about.hero.title'},
        ]}
      />
      <Hero
        title="about.hero.title"
        subtitle="about.hero.subtitle"
        description="about.hero.description"
      />
      <Bullets />
      <Figures />
      <About />
      <Director />
    </Page>
  )
}

export default AboutPage